const conceptionWeb = [
    {
        name: 'Site vitrine',
        price: 590,
        price_background: 'blue',
        sign: 'code',
        summary: ['Design personnalisé', 'Site responsive', 'Référencement (optimisé SEO)'],
        contents: ['Design <span class="txt-red fw-r">personnalisé</span>', '<span class="txt-blue fw-r">Site responsive</span>', '1 à 5 pages intégrées', 'Formulaire de contact et plan d\'accès', 'Création et configuration de 3 adresses <span class="txt-green fw-r">mail personnalisées</span>', 'Liens vers vos réseaux sociaux', 'Référencement (optimisé <span class="txt-yellow fw-r">SEO</span>)', 'Hébergement et nom de domaine inclus pour 1 an', 'Statistiques Google Analytics'],
        sentence: 'Artisan ou artiste souhaitant présenter leurs travails.',
    },{
        name: 'Site e-commerce',
        price: 1290,
        price_background: 'red',
        sign: 'function',
        summary: ['Design personnalisé', 'Boutique en ligne sécurisée', 'Référencement (optimisé SEO)'],
        contents: ['Design <span class="txt-red fw-r">personnalisé</span>', '<span class="txt-green fw-r">Boutique</span> en ligne <span class="txt-green fw-r">sécurisée</span>', '<span class="txt-yellow fw-r">Site responsive</span>', 'Administration en backoffice', 'Configurations paiement et transport selon vos prestataires', 'Création et configuration de <span class="txt-blue fw-r">10</span> adresses <span class="txt-blue fw-r">mail personnalisées</span>', 'Référencement (optimisé <span class="txt-pink fw-r">SEO</span>)', 'Hébergement sur serveur dédié et nom de domaine inclus pour 1 an', 'Statistiques Google Analytics'],
        sentence: 'Proposer vos produits à vos clients directement chez eux.',
    },{
        name: 'Site CMS',
        price: 1490,
        price_background: 'pink',
        sign: 'arrow',
        summary: ['Site responsive','Administration en backoffice','Référencement (optimisé SEO)'],
        contents: ['Design <span class="txt-yellow fw-r">personnalisé</span>', '<span class="txt-pink fw-r">Site responsive</span>', 'Nombre de pages à la demande', 'Administration en backoffice', 'Création et configuration de 5 adresses <span class="txt-green fw-r">mail personnalisées</span>', 'Liens vers vos réseaux sociaux', 'Référencement (optimisé <span class="txt-red fw-r">SEO</span>)', 'Hébergement sur serveur dédié et nom de domaine inclus pour 1 an', 'Statistiques Google Analytics'],
        sentence: 'Pour un besoin spécifique, le CMS répondra à votre besoin.',
    },
]
module.exports = conceptionWeb;