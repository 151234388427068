<template>
    <Menu active="service" />
    <div class="w-full flex-col">
        <TitleSection
            h2Title="Conception web_"
            subTitle="function {"
            titleContSize="w-53"
            txtContSize="w-47"
            txtTitle="Conception web sur mesure, adaptée à vos besoins, car chaque entreprise, chaque projet est spécial. Vous avez une idée, une inspiration ou un concept, je répondrai à vos demandes pour construire un site web adapté à vos clients et en adéquation avec l'image de l'entreprise. Je dispose d'une spécificité sur le marché de l'alimentation et des boissons avec une grande expertise et plusieurs sites à mon actif dans ce domaine."
            />
        <div class="w-full flex-row tel-flex-col">
            <ColService v-for="(card, index) in dataServicesWeb" :key="index"
                :info_service="card"
                service_resume="false"
                :idService="index" />
        </div>
        <router-link
            class="w-full bg-green txt-28 txt-center p-v-md b-b b-l b-r"
            to="/realisations" >
            <div class="hooksBtn">
                <p>'<span>dernières réalisations</span>'</p>
            </div>
        </router-link>
        <Footer />
    </div>
</template>

<script>
//BDD
import dbCWeb from '../../databases/__conceptionsWeb'

import { onMounted, ref } from 'vue'
// COMPONENTS
import Menu from "../../components/_partials/Menu.vue";
import TitleSection from "../../components/_partials/TitleSection.vue";
import ColService from "../../components/ColService.vue";
import Footer from "../../components/_partials/Footer.vue";

export default {
    components: {
        Menu,
        TitleSection,
        ColService,
        Footer,
    },
    setup() {
        class ServicesWeb {
            constructor (name, price, summary, contents, price_background, sign, sentence) {
                this.name = name
                this.price = price
                this.summary = summary
                this.contents = contents
                this.price_background = price_background
                this.sign = sign
                this.sentence = sentence
            }
        }

        let dataServicesWeb = ref([])

        const makeDataServicesWeb = () => {

            for (const service of dbCWeb) {
                const new_service = new ServicesWeb(service.name, service.price, service.summary, service.contents, service.price_background, service.sign, service.sentence)
                dataServicesWeb.value.push(new_service)
            }
        }

        onMounted(makeDataServicesWeb);

        //Return
        return {
            dataServicesWeb,
        }
    },
    mounted() {  
        document.title = 'Trouvez le service dont vous avez besoin.';  
    },
};
</script>

<style>

</style>