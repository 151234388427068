<template>
    <div
        class="w-333 flex-col tab-w-50 tel-w-full as-s"
        id="serviceCard">
        <div
            class="w-full flex-col jc-c ai-c txt-green b-b b-r"
            :class="[idService == 0 ? 'b-l' : '']" >
            <p
                id="nameService"
                :class="info_service.sign + 'Sign'" >
                _{{ info_service.name }}
            </p>
        </div>
        <div class="w-full flex-row">
            <div
                class="w-67 b-b b-r"
                :class="[idService == 0 ? 'b-l' : '']"
                id="shortDescServiceCard" >
                {{ info_service.sentence }}
            </div>
            <div
                class="w-33 flex-col jc-c ai-fe b-b b-r txt-white"
                :class="'bg-'+info_service.price_background"
                id="priceTagServiceCard" >
                {{ info_service.price }} €
            </div>
        </div>
        <div
            class="w-full flex-col jc-sb as-s b-b b-r"
            :class="[idService == 0 ? 'b-l' : '']"
            id="listContainerServiceCard" >
            <ul v-if="service_resume == 'true'">
                <li v-for="sentence in info_service.summary" :key="sentence">{{ sentence }}</li>
                <li>...</li>
            </ul>
            <ul v-else>
                <li v-for="sentence in info_service.contents" :key="sentence" v-html="sentence"></li>
            </ul>
            <HooksBtn btnText="en savoir plus" link="/creationweb" />
        </div>
    </div>
</template>

<script>
// COMPONENTS
import HooksBtn from './_partials/buttons/HooksBtn.vue'

export default {
    name: 'ColService',
    components: {
        HooksBtn,
    },
    props: {
        info_service: Object,
        service_resume: String,
        idService: Number,
    },
}
</script>