<template>
    <router-link :to="link">
        <div class="hooksBtn txt-24">
            <p>"<span>{{ btnText }}</span>"</p>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'HooksBtn',
    props: {
        btnText: String,
        link: String,
    }
}
</script>

<style>
div.hooksBtn {
    cursor: pointer;
}
.hooksBtn p {
    color: #F8AC00;
    cursor: pointer;
}
.hooksBtn p span {
    text-decoration: underline;
}
.hooksBtn p::before {
    content: '[';
    color: #131313;
}
.hooksBtn p::after {
    content: ']';
    color: #131313;
}
.hooksBtn:hover > p span {
    animation: hoverEffect 2s ease-out forwards;
}
@keyframes hoverEffect {
    from {
        text-decoration-color: #F8AC00;
        text-decoration-style: wavy;
    }
    12% {
        text-decoration-color: transparent;
    }
    25% {
        text-decoration-color: #F8AC00;
        text-decoration-style: solid;
    }
    65% {
        text-decoration-color: transparent;
    }
    to {
        text-decoration-color: #F8AC00;
        text-decoration-style: wavy;
    }
}
</style>